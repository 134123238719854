import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  // Komponenten, die im gesamten Applikationsmodul sichtbar sind
  // und von jeder anderen Komponente angesprochen werden können.
  declarations: [
    AppComponent
  ],
  // Module für das anzusprechende Zielsystem. (Webbrowser = BrowserModule)
  // ggf. auch was für mobile Geräte
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  // ??
  providers: [],
  // Die Einstiegskomponente in die Applikation.
  // Quasi die Root-Kompomente
  bootstrap: [AppComponent]
})
export class AppModule { }
