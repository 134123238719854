import { NONE_TYPE, ThisReceiver } from '@angular/compiler';
import { Component } from '@angular/core';
//import { FormsModule } from '@angular/forms';

// Konfiguration und Anmeldung der Komponente bei Angular.
// (Dekorator)
@Component({
  // Zeigt an, an welcher Stelle die Komponente verwendet werden soll.
  selector: 'app-root',
  //templateUrl: './app.component.html',
  templateUrl: './min_view.html',
  styleUrls: ['./app.component.css']
})

// Die eigentliche Komponente als TypeScript-Klasse.
export class AppComponent {
  title = 'SmartGrid';

  //logs:Array<string> = [];
  nun:string = "";
  myInputString:string = "";

  // Event aus dem min_view.html mit leerem Array zum Speichern.
  toDoArray:Array<string>=[];
  addToDo(aItem:string) {
    this.toDoArray.push(aItem);
    this.toDoArray.sort();
    if ( this.myInputString != "" ) {
      this.myInputString = "";
    } else {
      this.myInputString = " ";
    }
  }
  deleteToDo(aItem:string) {
    this.toDoArray[this.toDoArray.indexOf(aItem)] = "";
    this.toDoArray.sort();
    this.toDoArray.reverse();
    this.toDoArray.pop();
    this.toDoArray.reverse();
  }

  testKey(aEvent: KeyboardEvent, aItem: string) {
    //this.logs.("");
    this.nun = aEvent.key;
    if (this.myInputString == '') {
      this.myInputString = " "
    }
    if (aEvent.key == "Enter") {
      this.addToDo(aItem);
    }
  }
}
