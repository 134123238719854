<h2>{{ title }}</h2>

<div><h4>{{ title }} app is running!</h4></div>

<!--
    Auch wenn es Formularfelder sind, dürfen sie nicht in einem
    Form-Tag eingeschlossen werden, da der Browser sonst beim
    Bestätigen die Seite neu laden würde.
    Eine lokale Variable für ein Element erstellt man mit einerm '#'.
    Hier z.B. "#myItem"... diese ist dann in der gesamten html Datei
    verfügbar.
-->
You can push some items to a list.<br/>
<input #myItem type="text" placeholder="Add entry"
    (keypress)="testKey($event, myItem.value)"
    [value]="myInputString" />
<!--
    An den Button wird ein Event gebunden was nach der Syntax:
    (eventName)="NameDesEvents"
    geschieht.
-->
<button (click)="addToDo(myItem.value)">Add</button>


<div class="container">
    Key pressed: '{{ nun }}'
    <!--li *ngFor="let lItem of logs">{{ lItem }}</li-->
</div>


<!--
    Erzeugen einer Liste aus dem "toDoArray" des Controllers der App.
    Also "app.component.ts". Dabei ist "*ngFor" der Befehl für eine 
    For-Schleife in Angular.
-->
<br/>
A simple item list:
<div class="data">
    <ul>
        <li *ngFor="let lItem of toDoArray">{{lItem}}
            <button (click)="deleteToDo(lItem)">Delete</button>
        </li>
    </ul>
</div>

